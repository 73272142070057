<template>
  <div class="content">
    <div class="qt-block-ui" v-if="loadPage"></div>
    <PageHeader title="Canais" />
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuWidget" v-model="menus.Widget">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>Widget</div>
          <span class="status bg-secondary channel-status bg-success"> Ativo </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Forneça suporte online instantâneo, capturar mais leads e construir melhores relacionamentos com os clientes. Mais do que um chat, um canal de suporte - Yuper Chat!
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6">
              <router-link
                to="/live/configs/widget"
                class="tile-primary"
              >
                <i class="fas fa-code"></i>
                <span>Instalar</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6">
              <router-link
                to="/live/simulator"
                class="tile-primary"
              >
                <i class="fas fa-external-link-alt"></i>
                <span>Simular</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-globe icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuWhatsApp" v-model="menus.WhatsApp">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>WhatsApp</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': whatsClient.status == 'active' }"> {{ whatsClient.status == 'active' ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Enriqueça seu atendimento no aplicativo de mensagens mais popular do mundo. Ofereça suporte imediato e uma experiência personalizada aos seus clientes com o WhatsApp Business API.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="whatsClient.status == 'active'">
              <router-link
                to="/whatsapp/send"
                class="tile-primary"
              >
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="whatsClient.status == 'active'">
              <router-link
                to="/whatsapp/templates"
                class="tile-primary"
              >
                <i class="fas fa-archive"></i>
                <span>Templates</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="whatsClient.status != 'active'">
              <a
                href="https://hub.360dialog.com/lp/whatsapp/YZKzG7PA"
                class="tile-success hide"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div v-if="this.whatsChannel.id" class="col-xs-12 col-md-12">
              <h3>Número</h3>
              <table class="table v-align-middle">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Nome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong class="nowrap limit-page">{{whatsChannel.identification_name}}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-whatsapp icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuWhatsAppMeta" v-model="menus.WhatsAppMeta">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>WhatsApp</div>
          <span class="status bg-warning channel-status" style="margin-right: 5px; margin-left: 5px;">Meta</span>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': channels[8].active }"> {{ channels[8].active ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Enriqueça seu atendimento no aplicativo de mensagens mais popular do mundo. Ofereça suporte imediato e uma experiência personalizada aos seus clientes com o WhatsApp Business API.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="channels[8].active">
              <router-link
                to="/whatsapp/send"
                class="tile-primary"
              >
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="channels[8].active">
              <router-link
                to="/whatsapp/templates"
                class="tile-primary"
              >
                <i class="fas fa-archive"></i>
                <span>Templates</span>
              </router-link>
            </div>
            <div class="icon col-xs-12 col-md-12">
              <a
                @click="metMessage"
                class="tile-success c-pointer"
              >
                <i class="fas fa-plus"></i>
                <span>Adicionar Número</span>
              </a>
            </div>
            <div v-if="this.whatsNumbers.length > 0" class="col-xs-12 col-md-12">
              <h3>Números</h3>
              <table class="table v-align-middle">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in whatsNumbers" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{item.phone_number}}</strong>
                    </td>
                    <td align="right">
                      <button class="btn btn-danger btn-rounded btn-sm" @click="removeNumber(item.phone_identification)">
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-whatsapp icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuMessenger" v-model="menus.Messenger">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between" style="text-align: left;">
          <div>Facebook Messenger</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': this.channels[4].active || this.pages.length > 0}"> {{ this.channels[4].active || this.pages.length > 0 ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Mantenha presença da marca, ofereça suporte eficaz ao cliente, aumente as conversões de vendas e expanda seus negócios com o Facebook Messenger.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="!this.channels[4].active && this.pages.length == 0">
              <a
                :href="messengerRouter"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="this.channels[4].active || this.pages.length > 0">
              <a
                :href="messengerRouter"
                class="tile-success"
              >
                <i class="fas fa-plus"></i>
                <span>Adicionar Página</span>
              </a>
            </div>
            <div v-if="this.channels[4].active || this.pages.length > 0" class="col-xs-12 col-md-12">
              <h3>Páginas</h3>
              <table class="table v-align-middle" style="z-index: 2000;">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in pages" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{item.name}}</strong>
                    </td>
                    <td align="right">
                      <button class="btn btn-danger btn-rounded btn-sm" @click="removePage(channels[4].channel_id, item.identification)">
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-facebook-messenger icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuInstagram" v-model="menus.Instagram">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between" style="text-align: left;">
          <div>Instagram</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': this.channels[6].active }"> {{ this.channels[6].active || this.pagesInstagram.length > 0 ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Melhore a satisfação do cliente e impulsione as vendas por meio de conversas significativas com seus clientes no Instagram.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="!this.channels[6].active || this.pagesInstagram.length == 0">
              <a
                :href="instagramRouter"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="this.channels[6].active && this.pagesInstagram.length > 0">
              <a
                :href="instagramRouter"
                class="tile-success"
              >
                <i class="fas fa-plus"></i>
                <span>Adicionar Instagram</span>
              </a>
            </div>
            <div v-if="this.channels[6].active || this.pagesInstagram.length > 0" class="col-xs-12 col-md-12">
              <h3>Intagrans</h3>
              <table class="table v-align-middle">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in pagesInstagram" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{item.name}}</strong>
                    </td>
                    <td align="right">
                      <button class="btn btn-danger btn-rounded btn-sm" @click="removeInstagramPage(item.identification)">
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-instagram icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuRCS" v-model="menus.RCS">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>RCS</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': rcsClient.status == 'active' }"> {{ rcsClient.status == 'active' ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Trata-se de um novo padrão de mensagens por celular, desenvolvido para substituir o antiquado SMS, que faz a cobrança através da franquia de dados e não por cada mensagem enviada.
          </p>
          <hr>
          <div class="row">
            <div class="icon col-xs-6 col-md-6" v-if="rcsClient.status == 'active'">
              <router-link
                to="/rcs/send"
                class="tile-primary"
              >
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="rcsClient.status != 'active'">
              <a
                href="https://yupchat.typeform.com/to/b0aG4Maq"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
          </div>
          <i class="fab fa-google icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu" ref="menuChannels" id="menuReclameAqui" v-model="menus.ReclameAqui">
      <aside>
        <header>Reclame Aqui</header>
        Conteudo aqui
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuTelegram" v-model="menus.Telegram">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between" style="text-align: left;">
          <div>Telegram</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': this.channels[7].active }"> {{ this.channels[7].active ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Envolva seus clientes no canal de mensagens do Telegram e forneça soluções instantâneas.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="!this.channels[7].active && this.bots.length == 0">
              <a
                @click="addTelegramBot"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </a>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="this.channels[7].active || this.bots.length > 0">
              <a
                @click="addTelegramBot"
                class="tile-success"
              >
                <i class="fas fa-plus"></i>
                <span>Adicionar bot</span>
              </a>
            </div>
            <div v-if="this.channels[7].active || this.bots.length > 0" class="col-xs-12 col-md-12">
              <h3>Bots</h3>
              <table class="table v-align-middle" style="z-index: 2000;">
                <thead class="bg-light">
                  <tr>
                    <th class="p-l-20">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in bots" :key="i">
                    <td>
                      <strong class="nowrap limit-page">{{item.firstName}}</strong>
                    </td>
                    <td align="right">
                      <button class="btn btn-danger btn-rounded btn-sm" @click="removeBot(item.botId)">
                        <i class="fas fa-trash color-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <i class="fab fa-telegram icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuSMS" v-model="menus.SMS">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>SMS</div>
          <span class="status bg-secondary channel-status bg-success"> Ativo </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Atraia e converta seus clientes por meio das mensagens de texto SMS. Configure sua campanha de SMS marketing em minutos e envie mensagens em massa para todos os seus clientes.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6">
              <router-link
                to="/sms/send"
                class="tile-primary"
              >
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6">
              <router-link
                to="/sms/templates"
                class="tile-primary"
              >
                <i class="fas fa-archive"></i>
                <span>Templates</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-sms icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <b-sidebar class="right-menu right-menu-channel" ref="menuChannels" id="menuMail" v-model="menus.Mail">
      <aside class="menu-channels">
        <header class="card-header d-flex justify-content-between">
          <div>Email</div>
          <span class="status bg-secondary channel-status" :class="{ 'bg-success': mailClient.active }"> {{ mailClient.active ? 'Ativo' : 'Inativo' }} </span>
        </header>
        <div class="menu-channels-body icon-set">
          <p>
            Comunique-se e fortaleça o relacionamento com seus clientes através do envio de e-mail. Crie jornadas personalizadas e campanhas de e-mail marketing.
          </p>
          <hr>
          <div class="row" style="padding-left:20px;padding-right:20px;">
            <div class="icon col-xs-6 col-md-6" v-if="mailClient.active">
              <router-link
                to="/mail/send"
                class="tile-primary"
              >
                <i class="fas fa-paper-plane"></i>
                <span>Enviar mensagem</span>
              </router-link>
            </div>
            <div class="icon col-xs-6 col-md-6" v-if="!mailClient.active">
              <router-link
                to="/mail/domains"
                class="tile-success"
              >
                <i class="fas fa-check"></i>
                <span>Ativar Canal</span>
              </router-link>
            </div>
          </div>
          <i class="fas fa-envelope-open icon-b"></i>
        </div>
      </aside>
    </b-sidebar>
    <section class="page-content container-fluid">
      <div class="row">
        <template v-for="(item, i) in channels">
          <div class="col-12 col-sm-6 col-lg-4 col-xl-3" :key="i">
            <button
              class="card"
              style="min-height: 250px !important;"
              :disabled="!item.available"
              v-b-toggle="item.anchor"
              @click="closeOthers(item.anchor)"
            >
              <header class="card-header d-flex justify-content-between">
                <div>{{item.name}}</div>
                <div>
                  <span
                    style="float: left;"
                    class="status bg-secondary"
                    :class="{ 'bg-success': item.active }"
                  >
                    {{item.active ? 'Ativo' : 'Inativo' }}
                  </span>
                  <span
                    style="float: left;"
                    class="status bg-warning"
                    v-if="item.meta"
                  >
                    Meta
                  </span>
                </div>
              </header>
              <section class="card-body">
                {{item.description}}
              </section>
              <i :class="item.icon"></i>
            </button>
          </div>
        </template>
      </div>
    </section>
    <active-telegram-modal id="active-telegram-modal" @send="checkMessenger"></active-telegram-modal>
    <active-whats-modal id="active-whats-modal" @send="checkMessenger"></active-whats-modal>
  </div>
</template>

<script>
// @ is an alias to /src
// import Swal from 'sweetalert2';
import PageHeader from '@/components/PageHeader.vue';
import RCSService from '@/services/rcs.service';
import WhatsAppService from '@/services/whatsapp.service';
import ChannelService from '@/services/channel.service';
import Swal from 'sweetalert2';
import MailService from '@/services/mail.service';
import axios from '@/plugins/axios';
import ActiveTelegramModal from '@/components/ActiveTelegramModal.vue';
import ActiveWhatsModal from '@/components/ActiveWhatsModal.vue';

const webApi = axios();

export default {
  name: 'webhooks',
  components: {
    PageHeader,
    ActiveTelegramModal,
    ActiveWhatsModal,
  },
  data() {
    let whatsId = process.env.VUE_APP_WHATS_ID;

    if (this.$live.isMeta) {
      whatsId = '7f000001-840f-1f77-8184-15a9aa6229ea';
    } else {
      whatsId = process.env.VUE_APP_WHATS_ID;
    }

    return {
      loadPage: false,
      rcsClient: {},
      mailClient: {},
      whatsClient: {},
      messengerChannel: {},
      instagramChannel: {},
      telegramChannel: {},
      whatsChannel: {},
      fetched: false,
      pages: [],
      bots: [],
      pagesInstagram: [],
      whatsNumbers: [],
      menus: {
        WhatsApp: false,
        WhatsAppMeta: false,
        Messenger: false,
        Instagram: false,
        RCS: false,
        Widget: false,
        SMS: false,
        ReclameAqui: false,
        Telegram: false,
      },
      channels: [
        {
          icon: 'fas fa-globe',
          name: 'Yuper Chat',
          anchor: 'menuWidget',
          active: true,
          available: true,
          channel_id: 0,
          description: 'Forneça suporte online instantâneo, capturar mais leads e construir melhores relacionamentos com os clientes. Mais do que um chat, um canal de suporte completo da - Yuper Chat!',
        },
        {
          icon: 'fab fa-whatsapp',
          name: 'WhatsApp',
          anchor: 'menuWhatsApp',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Enriqueça seu atendimento no aplicativo de mensagens mais popular do mundo. Ofereça suporte imediato e uma experiência personalizada aos seus clientes com o WhatsApp Business API.',
        },
        {
          icon: 'fab fa-google',
          name: 'RCS Google',
          anchor: 'menuRCS',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Melhore a experiência do cliente e o marketing móvel. Envie experiências ricas, interativas e personalizadas diretamente no aplicativo de mensagens padrão em dispositivos Android.',
        },
        {
          icon: 'fas fa-sms',
          name: 'SMS',
          anchor: 'menuSMS',
          active: true,
          available: true,
          channel_id: 0,
          description: 'Atraia e converta seus clientes por meio das mensagens de texto SMS. Configure sua campanha de SMS marketing em minutos e envie mensagens em massa para todos os seus clientes.',
        },
        {
          icon: 'fab fa-facebook-messenger',
          name: 'Facebook Messenger',
          anchor: 'menuMessenger',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Mantenha presença da marca, ofereça suporte eficaz ao cliente, aumente as conversões de vendas e expanda seus negócios com o canal Facebook Messenger.',
        },
        {
          icon: 'fas fa-envelope-open',
          name: 'Email',
          anchor: 'menuMail',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Comunique-se e fortaleça o relacionamento com seus clientes através do envio de e-mail. Crie jornadas personalizadas e campanhas de e-mail marketing.',
        },
        {
          icon: 'fab fa-instagram',
          name: 'Instagram',
          anchor: 'menuInstagram',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Melhore a satisfação do cliente e impulsione as vendas por meio de conversas significativas com seus clientes no Instagram.',
        },
        {
          icon: 'fab fa-telegram',
          name: 'Telegram',
          anchor: 'menuTelegram',
          active: false,
          available: true,
          channel_id: 0,
          description: 'Envolva seus clientes no canal de mensagens do Telegram e forneça soluções instantâneas.',
        },
        {
          icon: 'fab fa-whatsapp',
          name: 'WhatsApp',
          anchor: 'menuWhatsAppMeta',
          active: false,
          available: true,
          meta: true,
          channel_id: 0,
          description: 'Enriqueça seu atendimento no aplicativo de mensagens mais popular do mundo. Ofereça suporte imediato e uma experiência personalizada aos seus clientes com o WhatsApp Business API.',
        },
      ],
      messengerId: process.env.VUE_APP_MESSENGER_ID,
      instagramId: process.env.VUE_APP_INSTAGRAM_ID,
      whatsId,
      telegramId: process.env.VUE_APP_TELEGRAM_ID,
      telegramIdLive: process.env.VUE_APP_TELEGRAM_LIVE_ID,
    };
  },
  computed: {
    messengerRouter() {
      return process.env.VUE_APP_FACEBOOK_ROUTE;
    },
    whatsRouter() {
      let redirectUri = 'https://dev.yup.chat/whatsapp/webhook-business';

      if (window.location.host.indexOf('app.') === 0) {
        redirectUri = 'https://app.yup.chat/whatsapp/webhook-business';
      }

      const url = `https://www.facebook.com/dialog/oauth?response_type=code&display=popup&app_id=571156307528909&redirect_uri=${encodeURIComponent(redirectUri)}&scope=business_management,whatsapp_business_management&state=${encodeURIComponent(this.$store.state.account.id)}&display=dialog`;
      console.log('URL');
      console.log(url);
      return url;
    },
    instagramRouter() {
      return process.env.VUE_APP_INSTAGRAM_ROUTE;
    },
  },
  created() {
    this.fetchRCS();
    this.fetchWhats();
    this.checkMessenger();
    this.checkDomains();
    this.checkClientActive();
    this.$root.$on('sentTelegram', this.checkMessenger);
  },
  methods: {
    checkClientActive() {
      if (this.$route.query.client !== null || this.$route.query.client !== undefined) {
        ChannelService.activeClient({
          client_id: this.$route.query.client,
        })
          .then(
            (send) => {
              console.log(send);
              this.loadPage = false;
              Swal.fire(
                'Ativado',
                'Canal ativado com sucesso',
                'success',
              );
              this.checkMessenger();
            },
            (error) => {
              this.$toast.show({
                title: 'Erro',
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          )
          .catch((error) => {
            console.log(error.response);
            this.isSending = false;
          });
      }
    },
    addTelegramBot() {
      this.$root.$emit('show.modal', 'active-telegram-modal');
    },
    removePage(channel, id) {
      Swal.fire({
        title: 'Remover página',
        text: 'Tem certeza que gostaria de remover essa página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then(
        (result) => {
          if (result.value) {
            this.loadPage = true;
            ChannelService.channelsRemovePage(channel, id).then(
              () => {
                this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
                this.loadPage = false;
                this.checkMessenger();
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Página removida',
                  type: 'success',
                });
              },
              (error) => {
                this.content = error;
              },
            );
          }
        },
      );
    },
    metMessage() {
      Swal.fire({
        title: 'Você tem uma conta business?',
        text: 'Tem uma conta business no facebook?',
        icon: 'warning',
        buttons: true,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then(
        (result) => {
          console.log(result);
          if (result.dismiss === 'cancel') {
            window.location.href = this.whatsRouter;
          }
          if (result.value) {
            this.$root.$emit('show.modal', 'active-whats-modal');
          }
        },
      );
    },
    removeBot(id) {
      Swal.fire({
        title: 'Remover bot',
        text: 'Tem certeza que gostaria de remover esse bot?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then(
        (result) => {
          if (result.value) {
            this.loadPage = true;
            ChannelService.removeBot(id, process.env.VUE_APP_TELEGRAM_ID, this.telegramIdLive).then(
              () => {
                this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
                this.loadPage = false;
                this.checkMessenger();
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Bot removido',
                  type: 'success',
                });
              },
              (error) => {
                this.content = error;
              },
            );
          }
        },
      );
    },
    removeInstagramPage(id) {
      Swal.fire({
        title: 'Remover página',
        text: 'Tem certeza que gostaria de remover essa página?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then(
        (result) => {
          if (result.value) {
            this.loadPage = true;
            ChannelService.channelsRemoveInstagram(id).then(
              () => {
                this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
                this.loadPage = false;
                this.checkMessenger();
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Página removida',
                  type: 'success',
                });
              },
              (error) => {
                this.content = error;
              },
            );
          }
        },
      );
    },
    removeNumber(number) {
      Swal.fire({
        title: 'Remover número',
        text: 'Tem certeza que gostaria de remover esse número?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then(
        (result) => {
          if (result.value) {
            this.loadPage = true;
            webApi.delete(`whatsapp/new/numbers/${number}`).then((response) => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loadPage = false;
              this.checkMessenger();
              this.$toast.show({
                title: 'Sucesso',
                content: 'Número removido',
                type: 'success',
              });
            }, (error) => {
              this.content = error;
              this.flowLoading = false;
            });
          }
        },
      );
    },
    checkDomains(page) {
      MailService.getDomains(page).then(
        (response) => {
          console.log(response);
          if (response.length > 0) {
            this.mailClient.active = true;
            this.channels[5].active = true;
          }
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    checkMessenger() {
      this.pagesInstagram = [];
      this.whatsNumbers = [];
      this.pages = [];
      this.getAccountChannel(this.whatsId);
      if ((this.$route.query.error === undefined || this.$route.query.error === null) && this.$route.query.channel != null) {
        this.loadPage = true;
        switch (this.$route.query.channel) {
          case 'instagram':
            ChannelService.webhookMessenger({
              channel_id: this.instagramId,
              channel_live_id: process.env.VUE_APP_INSTAGRAM_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/instagram',
            })
              .then(
                (send) => {
                  console.log(send);
                  this.loadPage = false;
                  Swal.fire(
                    'Ativado',
                    'Canal ativado com sucesso',
                    'success',
                  );
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          case 'messenger':
            ChannelService.webhookMessenger({
              channel_id: this.messengerId,
              channel_live_id: process.env.VUE_APP_MESSENGER_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/messenger',
            })
              .then(
                (send) => {
                  console.log(send);
                  this.loadPage = false;
                  Swal.fire(
                    'Ativado',
                    'Canal ativado com sucesso',
                    'success',
                  );
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          case 'whatsapp-java':
            ChannelService.webhookMessenger({
              channel_id: this.whatsId,
              channel_live_id: process.env.VUE_APP_WHATS_LIVE_ID,
              account_identification: this.$route.query.code,
              external_id: this.$route.query.code,
              end_point_send: 'http://localhost:8097/send/messenger',
            })
              .then(
                (send) => {
                  console.log(send);
                  this.loadPage = false;
                  Swal.fire(
                    'Ativado',
                    'Canal ativado com sucesso',
                    'success',
                  );
                },
                (error) => {
                  this.$toast.show({
                    title: 'Erro',
                    content: error.response.data.message,
                    type: 'danger',
                  });
                  this.isSending = false;
                },
              )
              .catch((error) => {
                console.log(error.response);
                this.isSending = false;
              });
            break;
          default:
            break;
        }
      }

      ChannelService.channels()
        .then(
          (response) => {
            console.log('Get channel');
            console.log(response);
            response.data.forEach((data) => {
              if (data.channel_id === this.messengerId) {
                this.messengerChannel = data;
                if (data.deleted === 'false') {
                  this.channels[4].active = true;
                  this.channels[4].channel_id = data.id;
                }
              }
              if (data.channel_id === this.instagramId) {
                this.instagramChannel = data;
                if (data.deleted === 'false') {
                  this.channels[6].active = true;
                  this.channels[6].channel_id = data.id;
                }
              }
              if (data.channel_id === this.telegramId) {
                this.telegramChannel = data;
                if (data.deleted === 'false') {
                  this.channels[7].active = true;
                  this.channels[7].channel_id = data.id;
                }
              }
              if (data.channel_id === this.whatsId) {
                console.log('Channel whats');
                console.log(data);
                this.whatsChannel = data;
                if (data.deleted === 'false') {
                  this.channels[1].active = true;
                  this.channels[1].channel_id = data.id;
                }
              }
            });
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
      ChannelService.channelsPage(process.env.VUE_APP_MESSENGER_LIVE_ID)
        .then(
          (response) => {
            console.log('Get pages');
            console.log(response.data);
            this.pages = [];
            const newPages = response.data;
            newPages.forEach((page, index) => {
              console.log(index);
              console.log(page);
              if (page) {
                console.log(page);
                if (page.length > 0) {
                  console.log(page[0].name);
                  this.pages.push(page[0]);
                }
              }
            });
            if (this.pages.length === 0) {
              this.channels[4].active = false;
            } else {
              this.channels[4].active = true;
            }
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });

      ChannelService.getTelegramBot()
        .then(
          (response) => {
            console.log('Get Bots Telegram');
            console.log(response.data);
            this.bots = [];
            response.data.forEach((item) => {
              if (item.deleted === false) {
                this.bots.push(item);
              }
            });
            if (this.bots.length === 0) {
              this.channels[7].active = false;
            }
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
      ChannelService.instagramPage(process.env.VUE_APP_INSTAGRAM_LIVE_ID)
        .then(
          (response) => {
            console.log('Get Instagrams');
            console.log(response);
            this.pagesInstagram = response.data;
            if (this.pagesInstagram.length === 0) {
              this.channels[6].active = false;
            }
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    getAccountChannel(channel) {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then((response) => {
        console.log('WhatsNumbers');
        console.log(response);
        response.data.forEach((data) => {
          this.whatsNumbers.push(data);
        });
        if (this.whatsNumbers.length > 0) {
          this.channels[8].active = true;
        }
      }, (error) => {
        this.content = error;
        this.flowLoading = false;
      });
    },
    fetchWhats() {
      Promise.all([
        WhatsAppService.getClient(),
      ])
        .then(
          (responses) => {
            this.whatsClient = responses[0].data;
            // this.channels[1].active = this.whatsClient.status === 'active';
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetchRCS() {
      Promise.all([
        RCSService.getAgent(),
      ])
        .then(
          (responses) => {
            this.rcsClient = responses[0].data;
            this.channels[2].active = this.rcsClient.status === 'active';
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetch(page) {
      this.fetched = false;
    },
    closeOthers(exceptThis) {
      const menus = this.menus;

      exceptThis = exceptThis.substring(4);

      Object.keys(menus).forEach((key) => {
        console.log('closeOthers', key);

        if (exceptThis !== key) menus[key] = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.m-10 {
  margin: 10px;
}
.right-menu::v-deep [id^="menu"] {
  overflow: hidden;
  position: fixed;
  z-index: 100;
  background: #fff !important;
  right: 0;
  top: 0;
  opacity: 1;
  width: 320px;
  height: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  box-shadow: 0 32px 25px 9px rgba(0,0,0,.12);
  transition: .2s all ease;
  display: block !important;

  &[aria-hidden="true"] {
    width: 0;
    opacity: 0;
    pointer-events: none;
  }

  & > aside {
    min-width: 320px;
    width: 100%;
  }
}

.card {
  position: relative;
  overflow: hidden;
  display: block;
  text-align: left;
  color: inherit;
  padding: 0;

  .card-header {
    font-weight: bold;

    .status {
      color: #fff;
      margin-top: 4px;
      padding: 2px 10px;
      border-radius: 16px;
      display: block;
      outline: 2px solid #fff;
      text-transform: uppercase;
      font-size: 7pt;
    }
  }

  .card-header, .card-body {
    position: relative;
    z-index: 10;
  }

  & > i {
    pointer-events: none;
    position: absolute;
    z-index: 9;
    right: -10px;
    bottom: -10px;
    font-size: 6.5em;
    color: #efefef;
  }
}
.menu-channels header {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  padding: 20px;
  color: #8a8a8a;
}
.menu-channels .icon-b {
  color: #f2f2f2;
  position: absolute;
  bottom: -8px;
  left: -8px;
  font-size: 100px;
  z-index: -1;
}
.channel-status {
  padding: 5px;
  color: #fff;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  padding-bottom: 0px;
  height: 30px;
  margin-right: 15px;
  font-weight: normal;
}
.menu-channels p {
  padding: 15px;
}
.menu-channels-body button {
  margin-left: 15px;
}
.tile-success {
  background-color: #2fbfa0 !important;
  border-radius: 10px;
  box-shadow: 0 15px 20px #2fbfa046 !important;
}
.tile-primary {
  background-color: #635ebe !important;
  box-shadow: 0 15px 20px rgba(99,94,190,.4) !important;
  border-radius: 10px;
}
.tile-primary span {
  color: #fff !important;
}
.tile-primary i {
  color: #fff !important;
}
.tile-success i {
  color: #fff !important;
}
.tile-success span {
  color: #fff !important;
}
.right-menu::v-deep [id^="menu"] {
  overflow-y: scroll !important;
}
.limit-page {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  display: block;
}
.color-white {
  color: #fff !important;
}
</style>
